import React, {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Container, Row, Col, Card} from 'react-bootstrap'
import {Button, Modal, Form} from 'react-bootstrap'
import {useState} from 'react'

import img1 from '../../../../_metronic/assets/inspections/inspect1.jpeg'
import img2 from '../../../../_metronic/assets/inspections/inspect2.jpeg'
import img3 from '../../../../_metronic/assets/inspections/inspect3.jpeg'
import Footer from './Footer'
import axios from 'axios'
import {useAuth} from '../../auth'

const statesMapping: {[key: number]: string} = {
  1: 'New South Wales',
  2: 'Victoria',
  3: 'Queensland',
  4: 'Western Australia',
  5: 'South Australia',
  6: 'Tasmania',
  7: 'Australian Capital Territory',
  8: 'Northern Territory',
}

const data = [
  {
    title: 'Get a free contract review!',
    content:
      'Receive a comprehensive review of your property contract from an expert. Ensure all legal aspects are covered before signing.',
  },
  {
    title: 'Get advice about your mortgage',
    content:
      'Consult with a mortgage advisor to understand your financing options and secure the best rates for your property purchase.',
  },
  {
    title: 'Consult with a negotiation expert',
    content:
      'Engage with a negotiation specialist to enhance your bargaining position and get the best deal on your property.',
  },
  {
    title: 'Get a valuation report for your property',
    content:
      'Obtain a detailed valuation report to assess the true market value of your property and make informed decisions.',
  },
]

interface Report {
  report_id: number
  report_date: string
  inspectionDate: string
  propertyAddress: string
  city: string
  state: string
  zipcode: string
  [key: string]: any
}

interface InspectorData {
  firstname: string
  lastname: string
  service_name: string
  phone_no: string
  pricing: string
  description: string
  certification_details: string
  insurance_details: string
  experience_years: number
  rating: number
  availability: string[]
  idOfInspector:number
}

const accordionData = [
  {
    cityName: 'New South Wales',
    years: [2022, 2023, 2024],
  },
  {
    cityName: 'Victoria',
    years: [2021, 2022, 2023],
  },
  {
    cityName: 'QueensLand',
    years: [2020, 2022, 2023, 2024],
  },
]

const InspectorDetailProfilePage: React.FC = () => {
  const [reportData, setReportData] = useState<Report[]>([])
  const location = useLocation()
  const inspectorData = (location.state as InspectorData) || {}
  const [step, setStep] = useState(1)
  const [selectedRole, setSelectedRole] = useState<string>('')
  const [checkedOptions, setCheckedOptions] = useState(new Set())
  const [show, setShow] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  // const [currentUserId, setCurrentUserId] = useState<number | null>(null)         
  console.log(inspectorData)

  const [clientDetails, setClientDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',
  })
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',
    state: '',
  })

  const navigate = useNavigate()

  const handleBookInspector = async () => {
    await navigate('/website/payment')
  }

  const filteredReports = reportData.filter((report) => {
    const fullAddress =
      `${report.propertyAddress} ${report.city} ${report.state} ${report.zipcode}`.toLowerCase()
    return fullAddress.includes(searchTerm.toLowerCase())
  })

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get<Report[]>('https://api.inspection.payplatter.in/api/inspection-reports')
        setReportData(response.data) // Set the entire array of reports
      } catch (error) {
        console.error('Error fetching reports:', error)
      }
    }

    fetchReports()
  }, [])

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: '2-digit'}
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'role' ? Number(value) : value,
    }))

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setClientDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // Handle change event for radio buttons
  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(e.target.value)
  }

  const handleClientCheckboxChange = (e: {target: {id: string; checked: boolean}}) => {
    const {id, checked} = e.target
    setCheckedOptions((prev) => {
      const newSet = new Set(prev)
      if (checked) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }

  const submitHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(formData)
    setStep(2)
  }

  // const auth = useAuth()
  // const currentUser = auth.currentUser
  // const currentUserId = currentUser?.user_id
  const currentInspectorId = inspectorData.idOfInspector
  // const currentInspectorId = 119
  console.log(currentInspectorId)

  const detailSubmitHandle = async () => {
    const requestBody = {
      userId: currentInspectorId,
      live: selectedRole === '1',
      invester: selectedRole === '3',
      buyersAgent: selectedRole === '2',
      conveyancer: selectedRole === '4',
      contract: checkedOptions.has('checkbox-0'),
      mortgage: checkedOptions.has('checkbox-1'),
      negotiation: checkedOptions.has('checkbox-2'),
      valuation: checkedOptions.has('checkbox-3'),
    }
    console.log(requestBody)

    try {
      const response = await axios.post('https://api.inspection.payplatter.in/api/request-detail', requestBody)
      console.log('Data submitted successfully:', response.data)
      navigate('/website/payment')
    } catch (error) {
      console.error('Error submitting data:', error)
    }
  }

  const handleSubmitClient = async () => {
    const requestBody = {
      userId: currentInspectorId,
      firstname: clientDetails.firstname,
      lastname: clientDetails.lastname,
      email: clientDetails.email,
      phone: clientDetails.phone_no,
    }
    console.log(requestBody)
    try {
      const response = await fetch('https://api.inspection.payplatter.in/api/client-detail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        console.log('Client data submitted successfully')
        navigate('/website/payment')
      } else {
        console.error('Failed to submit client data')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <>
      <section className='ezy__header7 light'>
        <Container className='position-relative' style={{marginLeft: '120px'}}>
          <Row className='align-items-center justify-content-center'>
            <Col lg={5} xl={4} className='mb-4 mb-lg-0 text-center text-lg-start order-2'>
              <Card className='price-card badge-light-success'>
                <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
                  <p className='price-text-p text-dark'>Price starting at just</p>
                  <p className='price-text text-success '>${inspectorData.pricing}</p>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={7} className='ps-lg-4 pe-xl-5 text-center text-lg-start'>
              <h2 className='ezy__header7-heading mb-4'>{inspectorData.service_name}</h2>
              <div className=''>
                <p className='ezy__header7-sub-heading badge badge-success'>
                  {inspectorData.firstname} {inspectorData.lastname}
                </p>
                <br></br>
                <br></br>
                <p className=''>
                  <Button className='btn btn-dark' onClick={handleBookInspector}>
                    Book Inspector
                  </Button>
                </p>
              </div>

              <div className='card w-75 mt-3 '>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <h3>About Us:</h3>
                  </h5>
                  <p className='card-text mt-3'>{inspectorData.description}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className='card  mt-3 '>
          <section className=' overflow-hidden' style={{marginLeft: '120px'}}>
            <h3>Gallery</h3>
            <Container className='py-5 py-lg-5 px-5'>
              <Row className='justify-content-left mb-4 mb-lg-5'>
                {/* Image Cards */}
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img1}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 1'
                    />
                  </a>
                </Col>
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img2}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 2'
                    />
                  </a>
                </Col>
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img3}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 3'
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <div className='card w-75 ' style={{margin: '0 auto', marginLeft: '100px'}}>
          <div className='card-body '>
            <h2 className='card-title mb-6'>Area Serviced</h2>
            <div className='accordion' id='accordionPanelsStayOpenExample'>
              {accordionData.map((item, index) => (
                <div className='accordion-item' key={index}>
                  <h2 className='accordion-header' id={`panelsStayOpen-heading-${index}`}>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#panelsStayOpen-collapse-${index}`}
                      aria-expanded='true'
                      aria-controls={`panelsStayOpen-collapse-${index}`}
                    >
                      {item.cityName}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapse-${index}`}
                    className='accordion-collapse collapse show'
                    aria-labelledby={`panelsStayOpen-heading-${index}`}
                  >
                    <div className='accordion-body'>
                      {item.years.map((year, yearIndex) => (
                        <span
                          key={yearIndex}
                          className='badge badge-success'
                          style={{marginRight: '5px'}}
                        >
                          {year}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className='reportCntn' style={{margin: '0 auto', marginLeft: '120px'}}>
        <h2 className='mb-3 mt-5'>Available Reports</h2>

        {/* Search Bar */}
        <input
          type='text'
          placeholder='Search by address, city, state, or zipcode'
          className='form-control mb-5 mt-3'
          style={{width: '50%'}}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <div className='d-flex flex-wrap'>
          {filteredReports.map((report) => (
            <div
              key={report.report_id}
              className='card shadow-lg mb-5 mx-2'
              style={{
                border: '1px #000000',
                borderRadius: '20px',
                width: '300px',
                height: '280px',
              }}
            >
              <div className='card-body'>
                <p
                  className='card-title text-primary mb-5 d-flex justify-content-between'
                  style={{fontWeight: 'bold'}}
                >
                  <span className='badge badge-success badge-sm'>Building Inspection Report</span>
                </p>

                <h2 className='card-text mt-3'>
                  {`${report.propertyAddress}, ${report.city} ${report.state} ${report.zipcode}`}
                </h2>

                <div className='mt-10'>
                  <small className='text-muted'>{formatDate(report.inspectionDate)}</small>
                </div>

                <div
                  className='d-flex justify-content-between align-items-center mt-3 pt-2'
                  style={{borderTop: '1px solid #e0e0e0', bottom: 0}}
                >
                  <button className='btn btn-sm PurchanseButton' onClick={handleShow}>
                    <strong> Purchase Report</strong>
                  </button>

                  <span className='badge badge-success badge-lg'>$49</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <Footer></Footer>
      </div>
      <style>
        {`
          .ezy__header7 {
            --bs-body-color: #373572;
            --bs-body-bg: rgb(255, 255, 255);
            --ezy-theme-color: rgb(13, 110, 253);
            --ezy-theme-color-rgb: 13, 110, 253;
            --ezy-shadow: 0px 4px 44px rgba(159, 190, 218, 0.37);

            background-color: var(--bs-body-bg);
            overflow: hidden;
            padding: 30px 0;
            position: relative;
          }

          @media (min-width: 768px) {
            .ezy__header7 {
              padding: 50px 0;
            }
          }

          .ezy__header7-heading {
            font-weight: bold;
            font-size: 25px;
            line-height: 25px;
            color: var(--bs-body-color);
          }

          @media (min-width: 768px) {
            .ezy__header7-heading {
              font-size: 45px;
              line-height: 50px;
            }
          }

          .ezy__header7-sub-heading {
            color:#ffffff;
            font-size: 13px;
            line-height: 1.2;
          }

          .ezy__header7-img {
            border: 5px solid rgba(244, 247, 253, 0.1);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10%;
          }


                 .price-card {
  height: 120px;
  width: 250px;
  background: #d4edda;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
}

.price-text {
  font-size: 35px;
  font-weight: bold;
  
}




.PurchanseButton {
  background-color: #ffffff; /* Default background */
  color: green; /* Text color */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.PurchanseButton:hover {
  background-color: rgba(144, 238, 144, 0.5); /* Light green on hover */
}

        `}
      </style>

      <Modal show={show} onHide={handleClose} size='lg' centered className='custom-modal-backdrop'>
        <Modal.Header closeButton>
          <Modal.Title>{step === 1 ? 'Enter your Details' : 'I want '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <Form onSubmit={submitHandle}>
              <Row>
                <Col>
                  <Form.Group controlId='firstname'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='firstname'
                      value={formData.firstname}
                      onChange={handleChange}
                      placeholder='Enter first name'
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId='lastname'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='text'
                      name='lastname'
                      value={formData.lastname}
                      onChange={handleChange}
                      placeholder='Enter last name'
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId='email' className='mt-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  placeholder='Enter email'
                />
              </Form.Group>

              <Form.Group controlId='phone_no' className='mt-3'>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  name='phone_no'
                  value={formData.phone_no}
                  onChange={handleChange}
                  placeholder='Enter phone number'
                />
              </Form.Group>

              <Form.Group controlId='state' className='mt-3'>
                <Form.Label>State</Form.Label>
                <Form.Control
                  as='select'
                  name='state'
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value=''>Select State</option>
                  {Object.entries(statesMapping).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div className='d-flex justify-content-end mt-4'>
                <Button variant='primary' type='submit' className='mt-4'>
                  Submit and Next
                </Button>
              </div>
            </Form>
          ) : (
            <Form>
              <Form.Group>
                <div className='row mb-3'>
                  <div className='col-md-6 mb-3'>
                    <Form.Check
                      type='radio'
                      label='To live in the property'
                      name='role'
                      value='1'
                      checked={selectedRole === '1'}
                      onChange={handleRoleChange}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <Form.Check
                      type='radio'
                      label='As a Buyers Agent'
                      name='role'
                      value='2'
                      checked={selectedRole === '2'}
                      onChange={handleRoleChange}
                    />
                  </div>
                </div>
                <div className='row mb-3'>
                  <div className='col-md-6 mb-3'>
                    <Form.Check
                      type='radio'
                      label='As an Investor'
                      name='role'
                      value='3'
                      checked={selectedRole === '3'}
                      onChange={handleRoleChange}
                    />
                  </div>
                  <div className='col-md-6 mb-3'>
                    <Form.Check
                      type='radio'
                      label='As a Conveyancer'
                      name='role'
                      value='4'
                      checked={selectedRole === '4'}
                      onChange={handleRoleChange}
                    />
                  </div>
                </div>
              </Form.Group>

              {selectedRole === '1' || selectedRole === '3' ? (
                <div className='card w-85' style={{margin: '0 auto'}}>
                  <div className='card-body'>
                    <div className='accordion' id='accordionPanelsStayOpenExample'>
                      {data.map((item, index) => (
                        <div className='accordion-item' key={index}>
                          <h2 className='accordion-header' id={`panelsStayOpen-heading-${index}`}>
                            <button
                              className='accordion-button'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#panelsStayOpen-collapse-${index}`}
                              aria-expanded='true'
                              aria-controls={`panelsStayOpen-collapse-${index}`}
                            >
                              <input
                                type='checkbox'
                                className='form-check-input me-2'
                                id={`checkbox-${index}`}
                                onChange={handleClientCheckboxChange}
                                style={{marginRight: '10px'}}
                              />
                              <strong className='text-black' style={{marginLeft: '10px'}}>
                                {item.title}
                              </strong>
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapse-${index}`}
                            className='accordion-collapse collapse show'
                            aria-labelledby={`panelsStayOpen-heading-${index}`}
                          >
                            <div className='accordion-body'>{item.content}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <Button variant='primary' type='button' onClick={detailSubmitHandle}>
                      Submit
                    </Button>
                  </div>
                </div>
              ) : null}

              {(selectedRole === '2' || selectedRole === '4') && (
                <div className='mb-4'>
                  <h2 className='mb-4'>Client Details</h2>
                  <Form.Group>
                    <Row>
                      <Col>
                        <Form.Group controlId='firstname'>
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type='text'
                            name='firstname'
                            className='mb-3'
                            value={clientDetails.firstname}
                            onChange={handleFormChange}
                            placeholder='first name'
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId='lastname'>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type='text'
                            name='lastname'
                            className='mb-3'
                            value={clientDetails.lastname}
                            onChange={handleFormChange}
                            placeholder='last name'
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      placeholder='Email'
                      className='mb-3'
                      value={clientDetails.email}
                      onChange={handleFormChange}
                    />

                    <Form.Label>Phone no</Form.Label>
                    <Form.Control
                      type='text'
                      name='phone_no'
                      placeholder='Phone Number'
                      className='mb-3'
                      value={clientDetails.phone_no}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-end'>
                    <Button variant='primary' type='button' onClick={handleSubmitClient}>
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InspectorDetailProfilePage
