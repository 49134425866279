/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'
import { CgProfile } from 'react-icons/cg'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const auth = useAuth()
  const role = auth.currentUser?.role

  if (role == 1) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
        

        <SidebarMenuItemWithSub
          // SidebarMenuItemWithSub
          to='/user/list'
          title='User Management'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/user/list' title='Users' hasBullet={true} />
          <SidebarMenuItem to='/user/inspector' title='Inspector' hasBullet={true} />
          <SidebarMenuItem to='/user/employee' title='Staff' hasBullet={true} />
          <SidebarMenuItem to='/user/assign-roles' title='Assign Permissions' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          // SidebarMenuItemWithSub
          to='/orderandpayment'
          title='Order And Payment'
          icon='/media/icons/duotune/general/gen051.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem
            to='/orderandpayment/manage-orders'
            title='Manage Orders'
            hasBullet={true}
          />
                <SidebarMenuItem
            to='/orderandpayment/financial-reports'
            title='Reports'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/orderandpayment/payment-refunds'
            title='Payment and Refunds'
            hasBullet={true}
          />
    

        </SidebarMenuItemWithSub>


        <SidebarMenuItemWithSub
          // SidebarMenuItemWithSub
          to=''
          title='Inspections Management'
          icon='/media/icons/duotune/general/gen051.svg'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem
            to='/inspections-management/inspection-requests'
            title='Inspection Requests'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/inspections-management/inspection-reports'
            title='Inspection Reports'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub> 

        {/* <SidebarMenuItem
          // to='/inspectionrequests'
          to='/inspectionrequests'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'Requests' })}
          fontIcon='bi-app-indicator'
        /> */}



        {/* <SidebarMenuItem
        to='/inspector/'
        title='Inspector Managment'
         icon='/media/icons/duotune/general/gen022.svg'
        fontIcon='bi-layers'
      >
        {/* <SidebarMenuItem to='/inspector/manage' title='Manage Inspector' hasBullet={true} />
        <SidebarMenuItem to='/inspector/onboard' title='Onboard Inspector' hasBullet={true} />
      */}

        {/* </SidebarMenuItem> */}

        {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
      </>
    )
  } else if (role == 2) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/user/profile'
          title='My Profile'
          icon='/media/icons/duotune/general/gen051.svg'
        />

        {/* <SidebarMenuItem
          to='/inspections'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'Inspections'})}
          fontIcon='bi-app-indicator'
        /> */}


        <SidebarMenuItemWithSub
          // SidebarMenuItemWithSub
          to='/reports'
          title='Reports'
          icon='/media/icons/duotune/art/art002.svg'
          // fontIcon='bi-layers'
          fontIcon='bi-app-indicator'
        >
          <SidebarMenuItem to='/reports/inspections' title='Inspections Reports' hasBullet={true} />

        </SidebarMenuItemWithSub>
      </>
    )
  }
  else if (role == 3) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/user/profile'
          title='My Profile'
          icon='/media/icons/duotune/general/gen051.svg'
        />

        <SidebarMenuItem
          to='/inspections/requestinspections'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'Request Inspections' })}
          fontIcon='bi-app-indicator'
        />
      </>
    )
  }

  else if (role == 4) {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        />

        <SidebarMenuItem
          to='/user/profile'
          title='My Profile'
          icon='/media/icons/duotune/general/gen051.svg'
        />

        <SidebarMenuItem
          to='/inspections/requestinspections'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'Request Inspections' })}
          fontIcon='bi-app-indicator'
        />


        {/* <SidebarMenuItemWithSub
          // SidebarMenuItemWithSub
          to='/reports'
          title='Reports'
          icon='/media/icons/duotune/art/art002.svg'
          // fontIcon='bi-layers'
          fontIcon='bi-app-indicator'
        >
          <SidebarMenuItem to='/reports/inspections' title='Inspections Reports' hasBullet={true} />
        
        </SidebarMenuItemWithSub> */}
      </>
    )
  }
  else {
    return (
      <>
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />

        <SidebarMenuItemWithSub
          to='/user/list'
          title='User Management'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          {/* <SidebarMenuItem to='/user/list' title='Users' hasBullet={true} /> */}
          <SidebarMenuItem to='/user/inspector' title='Inspector' hasBullet={true} />
          <SidebarMenuItem to='/user/requests' title='Inspection Requests' hasBullet={true} />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
          to='/assign/requests'
          title='Requests Management'
          icon='/media/icons/duotune/art/art002.svg'
          fontIcon='bi-layers'
        >
          {/* <SidebarMenuItem to='/user/list' title='Users' hasBullet={true} /> */}
          {/* <SidebarMenuItem to='/user/inspector' title='Inspector' hasBullet={true} /> */}
          <SidebarMenuItem to='/assign/requests' title='Inspection Requests' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </>
    )
  }
}

export { SidebarMenuMain }
