import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import { useAuth } from '../../../../../app/modules/auth'

export function MenuInner() {
  const intl = useIntl()
  const auth = useAuth()
  const role = auth.currentUser?.role

  if (role == 1) {
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
  

      <MenuInnerWithSub
          title='User Managment'
          to='User-Managment'
          icon='/media/icons/duotune/general/gen025.svg'

          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/user/list' title='Users' hasBullet={true} />
          <MenuItem to='/user/inspector' title='Inspector' hasBullet={true} />
          <MenuItem to='/user/assign-roles' title='Assign Permissions' hasBullet={true} />
        
      </MenuInnerWithSub>


      {/* <MenuInnerWithSub
          // SidebarMenuItemWithSub
          to='/orderandpayment'
          title='Order And Payment'
          icon='/media/icons/duotune/general/gen051.svg'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/orderandpayment/manage-orders' title='Manage Orders' hasBullet={true} />
          <MenuItem to='/orderandpayment/payment-refunds' title='Payment and Refunds' hasBullet={true} />
          <MenuItem to='/orderandpayment/financial-reports' title='Financial Reports' hasBullet={true} />
        </MenuInnerWithSub> */}


{/* 
      <MenuInnerWithSub
          title='Inspector Managment'
          to='Inspector-Managment'
              icon='/media/icons/duotune/general/gen022.svg'
       
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
      
          <MenuItem to='/inspector/manage' title='Manage Inspector' hasBullet={true} />
          <MenuItem to='/inspector/onboard' title='Onboard Inspector' hasBullet={true} />
        
      </MenuInnerWithSub> */}
    </>
  )
}
else if (role == 2) {
  return (
    <>
      <MenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        icon='/media/icons/duotune/general/gen025.svg'
        // fontIcon='bi-layers'
      />

      <MenuItem
        to='/user/profile'
        title='My Profile'
        icon='/media/icons/duotune/general/gen051.svg'
      />

      {/* <MenuItem
        to='/reports'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'Inspections'})}

      /> */}


      
      <MenuInnerWithSub
       to='/reports'
       icon='/media/icons/duotune/art/art002.svg'
       title={intl.formatMessage({id: 'Reports'})}

          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/reports/inspections' title='Inspections Reports' hasBullet={true} />
  
        
      </MenuInnerWithSub>
    </>
  )
} 
else if (role == 3) {
  return (
    <>
      <MenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        icon='/media/icons/duotune/general/gen025.svg'
        // fontIcon='bi-layers'
      />

      <MenuItem
        to='/user/profile'
        title='My Profile'
        icon='/media/icons/duotune/general/gen051.svg'
      />

      {/* <MenuItem
        to='/reports'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'Inspections'})}

      /> */}


      
      <MenuInnerWithSub
       to='/inspections/requestinspections'
       icon='/media/icons/duotune/art/art002.svg'
       title={intl.formatMessage({id: 'Reports'})}

          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/inspections/requestinspections' title='Inspections Reports' hasBullet={true} />
  
        
      </MenuInnerWithSub>
    </>
  )
} 


else if (role == 4) {
  return (
    <>
      <MenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        icon='/media/icons/duotune/general/gen025.svg'
        // fontIcon='bi-layers'
      />

      <MenuItem
        to='/user/profile'
        title='My Profile'
        icon='/media/icons/duotune/general/gen051.svg'
      />

      {/* <MenuItem
        to='/reports'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'Inspections'})}

      /> */}

{/* 
      
      <MenuInnerWithSub
       to='/reports'
       icon='/media/icons/duotune/art/art002.svg'
       title={intl.formatMessage({id: 'Reports'})}

          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/reports/inspections' title='Inspections Reports' hasBullet={true} />
  
        
      </MenuInnerWithSub> */}
    </>
  )
} 
else {
  return (
    <>
      <MenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />


    </>
  )
}
}






