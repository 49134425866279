import axios from 'axios'
import React, {useEffect, useState, CSSProperties} from 'react'
import {useAuth} from '../../auth'

interface User {
  user_id: number
  username: string
  password: string
  email: string
  role: number
  firstname: string
  lastname: string
  phone_no: string
  is_disabled: boolean
  profile_photo_url: null | string
  availability: null | string[]
  signature_url: null | string
  state_id: number
}

interface Property {
  property_id: number
  address: string
  city: string
  state: string
  zipcode: string
}

interface Inspector {
  inspector_id?: number
  certification_details?: string
  insurance_details?: string
  experience_years?: number
  rating?: number
  is_disabled?: boolean
  firstname?: string
  lastname?: string
  phone_no?: string
  availability?: any[]
  terms?: boolean
  cancel_policy?: boolean
  code_conduct?: boolean
}

interface InspectionRequest {
  request_id: number
  request_date: string
  status: boolean
  inspection_date: null | string
  requester_role: number
  assigned_date: null | string
  accept: boolean
  property: Property
  requested_by: User
  assigned_inspector: null | Inspector
}

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
}

const roleMapping: { [key: number]: string } = {
    3: 'Buyer',
    4: 'Seller',
    2: 'Inspector',
  };

const ManageOrders = () => {
  const [requests, setRequests] = useState<InspectionRequest[]>([])
  const [filteredRequests, setFilteredRequests] = useState<InspectionRequest[]>([])
  const [loading, setLoading] = useState(true)
  const [statusFilter, setStatusFilter] = useState<string>('All') // New state for filtering by status
  // const [assignedInspectors, setAssignedInspectors] = useState<string>('All'); // New state for filtering by assigned inspector

  const auth = useAuth()
  const currentUser = auth.currentUser
  const currentUserId = currentUser?.user_id

  const fetchRequests = async () => {
    try {
      const response = await axios.get('https://api.inspection.payplatter.in/api/inspection-requests')
      setRequests(response.data)
      setFilteredRequests(response.data) // Initialize the filtered requests with all data
      // setAssignedInspectors(response.data); // filter for assigned inspectors
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const notDefined = (
    <span className={`badge badge-light-danger fs-7 fw-semibold`}>Not Assigned</span>
  )

  useEffect(() => {
    fetchRequests()
  }, [])

  // Status
  useEffect(() => {
    if (statusFilter === 'All') {
      setFilteredRequests(requests)
    } else {
      const filtered = requests.filter((request) =>
        statusFilter === 'Active' ? request.status : !request.status
      )
      setFilteredRequests(filtered)
    }
  }, [statusFilter, requests])

  const handleStatusFilterChange = (status: string) => {
    setStatusFilter(status)
  }

  // Assigned Inspectors
  // useEffect(() => {
  //     if (assignedInspectors === 'All') {
  //         setFilteredRequests(requests);
  //     }
  //     else {
  //         const filtered = requests.filter((request) =>
  //             assignedInspectors === 'Not Assigned' ? request.assigned_inspector === null : request.assigned_inspector?.inspector_id === parseInt(assignedInspectors)
  //         );
  //         setFilteredRequests(filtered);
  //     }
  // }, [assignedInspectors, requests]);

  // const handleAssInspectFilterChange = (status: string) => {
  //     setAssignedInspectors(status);
  // }

  return (
    <div>
      <div className={`card`}>
        <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-center'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Inspection Requests</span>
          </h3>

          {/* Dropdown Filter */}
          <div className='btn-group'>
            <button
              className='btn btn-secondary btn-sm dropdown-toggle me-15'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              {statusFilter}
            </button>
            <ul className='dropdown-menu'>
              <li>
                <button className='dropdown-item' onClick={() => handleStatusFilterChange('All')}>
                  All
                </button>
              </li>
              <li>
                <button
                  className='dropdown-item'
                  onClick={() => handleStatusFilterChange('Active')}
                >
                  Active
                </button>
              </li>
              <li>
                <button
                  className='dropdown-item'
                  onClick={() => handleStatusFilterChange('Inactive')}
                >
                  Inactive
                </button>
              </li>
            </ul>
            {/* <button className="btn btn-secondary btn-sm dropdown-toggle me-15" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {statusFilter}
                        </button>
                        <ul className="dropdown-menu">
                            <li><button className="dropdown-item" onClick={() => handleFilterChange('All')}>All</button></li>
                            <li><button className="dropdown-item" onClick={() => handleFilterChange('Active')}>Active</button></li>
                            <li><button className="dropdown-item" onClick={() => handleFilterChange('Inactive')}>Inactive</button></li>
                        </ul> */}
          </div>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {loading ? (
              <span className='center'>Loading Inspection Requests......</span>
            ) : (
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Request Date</th>
                    <th className='min-w-150px'>Property</th>
                    <th className='min-w-150px'>Requested by</th>
                    <th className='min-w-150px'>Requester Role</th>
                    <th className='min-w-150px'>Inspection Date</th>
                    <th className='min-w-150px'>Assign Inspector</th>
                    <th className='min-w-150px'>Assigned Date</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Request Accepted</th>
                    <th className='min-w-100px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRequests.map(
                    (request): React.ReactNode => (
                      <tr key={request.request_id}>
                        {/* <td>{new Date(request.request_date).toLocaleDateString()}</td> */}
                        <td>
                          <td>
                            {request.request_date ? (
                              new Date(request.request_date).toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                              })
                            ) : (
                              <>N/A</>
                            )}
                          </td>
                        </td>
                        {/* <td>{`${request.property.address}, ${request.property.city}`}</td> */}
                        <td>
                          {request.property.address}, {request.property.city},{' '}
                          {request.property.state}, {request.property.zipcode}
                        </td>
                        <td>{`${request.requested_by.firstname} ${request.requested_by.lastname}`}</td>
                        {/* <td>{request.requested_by.role}</td> */}
                        <td>{roleMapping[request.requested_by.role]}</td>

                        <td>{request.inspection_date ? new Date(request.inspection_date).toLocaleDateString() : 'N/A'}</td>
                         {/* <td>{request.assigned_inspector? `${request.assigned_inspector.firstname} ${request.assigned_inspector.lastname}`: 'N/A'}</td> */}
                         <td>
                          {request.assigned_inspector?.firstname}{' '}
                          {request.assigned_inspector?.lastname || notDefined}
                        </td>
                         <td>{request.assigned_date ? new Date(request.assigned_date).toLocaleDateString() : 'N/A'}


                            
                         </td>



                        <td>
                          {request.status ? (
                            <span className={`badge badge-light-success fs-7 fw-semibold`}>
                              {' '}
                              Active{' '}
                            </span>
                          ) : (
                            <span className={`badge badge-light-danger fs-7 fw-semibold`}>
                              Inactive
                            </span>
                          )}
                        </td>
                       
                       
                        <td>
                          {request.accept ? (
                            <span className={`badge badge-light-success fs-7 fw-semibold`}>
                              Yes
                            </span>
                          ) : (
                            <span className={`badge badge-light-danger fs-7 fw-semibold`}>No</span>
                          )}
                        </td>
                        <td>
                          <button className='btn btn-primary btn-sm'>Manage</button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManageOrders
